import axios from "axios";

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? `https://kushprakash.com/`
      : `https://kushprakash.com/`,
});

const imageUrl =
  process.env.NODE_ENV === "development"
    ? "https://kushprakash.com/images"
    : "https://kushprakash.com/images";

export default api;
export { imageUrl };
